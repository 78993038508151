<template>
 <div style='width: 100%; height: 100%; position: relative;'>
  
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 100%;'> 
	    <!-- HEADER -->
	    <div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
		change password for <b>{{user.login}} @ {{ tenant() }}</b>
	  	</div>
	    <!-- HEADER -->

	    <!-- TABLE -->
	    <div style='width: 100%; padding-left: 10%; padding-top: 5%; height: calc(100vh - 120px); overflow-y: scroll;'>
	    	<div v-if="user.oid">
	    		user is handled in an external single sign on system. please update the password there.
	    	</div>
	    	<!--
	    	<div v-else-if="pwChangeDisabled">
	    		Password change is not possible (password has to stay for at least {{minRule.value}} day(s)
	    	</div>
	    	-->
			
	    	<div v-else style='width: 100%; float: top'>
	    	   <div style='width: 60%; float: left;'>
	    	       <div v-if="pwChangeDisabled" >
						<div v-if="changeSuccess" style='color: green; font-weight: bold; padding: 10pt;'>
							Password successfully changed.<br/>
							Next Password change is possible in {{minRule.value}} day(s)
						</div>
						<div v-else style='color: red; font-weight: bold; padding: 10pt;'>
			    			Password change is not possible (password has to stay for at least {{minRule.value}} day(s)
						</div>
			    	</div>
			    	
		    	   <table style='float: left; width:100%;'>
		    	   <tr>
			    	   <td>
			    			<b style='padding-right:5pt; padding: 4pt;'>User:</b>
			    	   </td>
			    	   <td>
			    			  <vSelect disabled :options="[{label: user.login}]" style='width:100%' v-model="user.login"> 
				    	      </vSelect>
				    	</td>
			    	</tr>
			    	<tr>
			    	   <td>
			    			<b style='padding-right:5pt; padding: 4pt;'>Old password:</b>
			    	   </td>
			    	   <td>
			    	   		<InputSecret :disabled="pwChangeDisabled" @enter="changePassword()" v-model='oldpw' :style='getFirstSecretStyle()'/>
				       </td>
			       </tr>
			       <tr>
			    	   <td>
			    			<b style='padding-right:5pt; padding: 4pt;'>New password:</b>
			    	   </td>
			    	   <td>
			    	   		<!--
			    	   		<password :toggle="true"
									    @score="showScore"
									    @feedback="showFeedback" v-model="newpw"/>
									    -->
						    <InputSecret :disabled="pwChangeDisabled" @enter="changePassword()" v-model='newpw' :style='getFirstSecretStyle()'/>
				       </td>
			       </tr>
			       <tr>
			    	   <td>
			    			<b style='padding-right:5pt; padding: 4pt;'>Repeat new password:</b>
			    	   </td>
			    	   <td>
			    	   		<InputSecret :disabled="pwChangeDisabled" @enter="changePassword()" v-model='newpw2' :style='getSecondSecretStyle()'/>
				       </td>
			       </tr>
			       <tr>
			    	   <td>
			    			
			    	   </td>
			    	   <td>
			    	   		<button :disabled="pwChangeDisabled || !allRulesValid()" class='button' @click='changePassword'>change password</button>
				       </td>
			       </tr>
			       </table>
			   </div>
		       <div :style="getAppStyle()+'float: right; width: 30%; margin-top: 40pt; '">
			       <div class='transition' :style="allRulesStyle()">Remaining rules to follow:</div>
					   <ul>
					   	<li v-for="(p,i) in policy" class='transition' :style="rulecheck(p,newpw)" :key="'p_'+i">{{p.name}}</li>
					   	
					   </ul>
				   </div>
				</div>
	    	
    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    
 </div>
</template>

<script>
import {HTTP, fwAPI, userAPI, authAPI, setReload, myLocale} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick'; 
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import InputSecret from '@/components/inputElements/InputSecret';
var timers = [];
import Password from 'vue-password-strength-meter'
export default {
  name: 'GFW Schedule View',
  
  components : {
	vSelect, InputSecret
  },
  data () {
    return {
		user: {},
		getAppStyle,
		oldpw: "",
		newpw: "",
		newpw2: "",
		changeSuccess: false,
		suggestions: "",
		minRule: {},
		pwChangeDisabled: false,
		warning: "",
		policy: [],
		myURL: encodeURIComponent( window.location.href),
    }
  },
  methods: {
    myLocale() { return myLocale();},
    tenant() { return sessionStorage.tenant ; },
    identity(x) { return x;},
    getSecondSecretStyle() {
    	if ( this.newpw2 && this.newpw===this.newpw2 ) { return "padding: 5pt; color: #2c2 !important;" }
    	if ( this.newpw2 && this.newpw!==this.newpw2 ) { return "padding: 5pt; color: #c22 !important;" }
    	return getAppStyle()+"padding: 5pt;";
    },
     getFirstSecretStyle() {
    	return getAppStyle()+"padding: 5pt;";
    },
    ruleValid(rule, pw)
    {
    	if ( rule.ruletypeValue === 'Minimum' )
    	{
    		if ( !rule.units )
    		{
    			return ( pw.length >= rule.value )
    		}
    		else
    		{
    			var regex = new RegExp( rule.units);
    			var count = 0;
    			for ( var i = 0; i < pw.length; i++)
    			{
    				let ch = pw.charAt( i);
    				if ( regex.exec(ch) )
    				{
    					count++;
    				}
    			}
    			return ( count >= rule.value )
    		}
    	}
    	if ( rule.ruletypeValue === 'Maximum' )
    	{
    		if ( !rule.units )
    		{
    			return ( pw.length <= rule.value )
    		}
    		else
    		{
    			regex = new RegExp( rule.units);
    			count = 0;
    			for ( i = 0; i < pw.length; i++)
    			{
    				let ch = pw.charAt( i);
    				if ( regex.exec(ch) )
    				{
    					count++;
    				}
    			}
    			return ( count <= rule.value )
    		}
    	}
    	return true;
    },
    allRulesValid()
    {
    	for ( var rule in this.policy )
    	{
    		if ( !this.ruleValid( this.policy[rule], this.newpw))
    		{
    			return false;
    		}
    	}
    	return this.oldpw && this.newpw && this.newpw === this.newpw2;
    },
    allRulesChecked()
    {
    	for ( var rule in this.policy )
    	{
    		if ( !this.ruleValid( this.policy[rule], this.newpw))
    		{
    			return false;
    		}
    	}
    	return true;
    },
    rulecheck( rule, pw)
    {
    	if ( this.ruleValid(rule, pw) )
    	{
    		return "color: #8c8; height: 0pt; font-size: 0pt; "
    	}
    	else
    	{
    		return "font-weight: bold;";
    	}
    },
    allRulesStyle( )
    {
    	if ( this.allRulesChecked() )
    	{
    		return "color: #8c8; height: 0pt; font-size: 0pt; "
    	}
    	else
    	{
    		return "font-size: 12pt; font-weight: bold;";
    	}
    },
    showFeedback ({suggestions, warning}) {
        this.suggestions = suggestions;
        this.warning = warning;

      },
      showScore (score) {
        //console.log('💯', score)
      },
    changePassword() {
    	if ( ! this.newpw )
    	{
    		 this.$toast.error("new password must not be empty", 'Error', { position: "topRight" });
    		 return;
    	} 
    	if ( this.newpw !== this.newpw2)
    	{
    		 this.$toast.error("new password must be repeated and be exactly the same", 'Error', { position: "topRight" });
    		  return;
    	} 
    	if ( !this.allRulesValid() )
    	{
    		this.$toast.error("all rules must be followed", 'Error', { position: "topRight" });
    		  return;
    	}
    	if ( this.oldpw ) {
    	     let that = this;
    	     
    	 	 HTTP.get( authAPI+"/validate/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId, {headers: {
                               "x-user": this.user.login,
                               "x-pw": this.oldpw
                            }})
                .then( response => {
                        let msg = response.data;
                        if ( msg.indexOf("error") >= 0 )
                        {
                        	msg = msg.substring(msg.indexOf("OOPS:")+5);
                        	that.$toast.error(msg, 'Error', { position: "topRight" });
                        }
                        else
                        {
                            that.user.password = that.newpw;
                         
                        	HTTP.put( userAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId, that.user)
                        	.then( response => {
		
		                        that.user = response.data;
		                        that.$toast.success('password for '+that.user.login+' changed.', 'Ok', { position: "topRight" });
		                        that.oldpw = "";
		                        that.newpw = "";
		                        that.newpw2 = "";
								that.changeSuccess = true;
		                        that.getUser();
			                }).catch(e => {
			              		 that.$toast.error(e.response.data, 'Error', { position: "topRight" });
								 that.changeSuccess = false;
			              		 that.getUser();
			              }) ;
                        }
                }).catch(e => {
              		 that.$toast.error(e.response.data, 'Error', { position: "topRight" });
              }) ;
    	}
    },
    getUser()
    {
    	let that = this;
    	HTTP.post( userAPI+"/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId)
	            .then( response => 
	            { 
	                that.user = response.data;
	                
	                HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/PasswordPolicy/allEntities/true/0") 
		            .then( response => 
		               { 
		              	 let array= response.data.data;
		              	 that.policy = new Array();
		              	 for ( var i in array)
		              	 {
		              	 	let rule = array[i];
		              	 	if ( rule.ruleValue === 'Characters' )
		              	 	{
		              	 		that.policy.push( rule);
		              	 	}
		              	 	if ( rule.ruleValue === 'Expiration' )
		              	 	{
		              	 		
		              	 		if ( rule.ruletypeValue === 'Minimum' )
		              	 		{
		              	 			let start = that.user.pwlUpdate;
		              	 			let now = new Date().getTime();
		              	 			let min = rule.value * 24 * 3600 * 1000;
		              	 			
		              	 			//alert( start+"-"+ max + "::" + (( now - start )));
		              	 			if ( ( now - start ) < min )
		              	 			{
		              	 				that.pwChangeDisabled = true;
		              	 				that.minRule = rule;
		              	 			}
		              	 		}
		              	 	}
		              	 }

		                 
		               }).catch(e => {
		                     that.$toast.error(e.response.data, 'Error', { position: "topRight" });
		             });
	                
	            }).catch(e2 => {
	            	   that.$toast.error(e2.response.data, 'Error', { position: "topRight" });
					   
	            });
    }
  },
  created() {
  	this.getUser();
  	initAppMode();
  },
  updated() {
  	//this.getUser();
 	}
}
</script>
<style scoped>
table, th, td {
  padding: 5px;
}
table {
  border-spacing: 39px;
}
 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
  }
.headerDay { 
  font-size: 12pt; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc; 
  background-color: #eef; 
 }
.adMain { 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  z-Index: 17; 
  background-color: #fff; 
  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 

    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}

.gridMain { position: absolute; z-Index: 2; text-align: center; width: 99% ;border: 1pt solid #aaa; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
   
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}

 .iButton {
    background-color: transparent;
    border: none;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.iButton:hover {
    color: #fff !important;
  	background-color: rgb(88, 218, 218);; 
} 

.iButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
</style>
